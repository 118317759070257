<template>
  <div class="card address">
    <div class="card-body">
      <div class="row">
        <div class="col-auto">
          <div class="badge badge-primary text-uppercase">
            {{ address.type }}
          </div>
        </div>

        <div class="col-auto ml-auto my-auto">
          <button
            class="btn btn-sm btn-outline-danger mr-3"
            @click="$emit('delete', address)"
          >
            <i class="far fa-trash"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-primary"
            @click="$emit('edit', address)"
          >
            <i class="far fa-edit"></i>
          </button>
        </div>
      </div>

      <div class="address-entry">
        <p v-if="address.line1">{{ address.line1 }}</p>
        <p v-if="address.line2">{{ address.line2 }}</p>
        <p v-if="address.line3">{{ address.line3 }}</p>
        <p v-if="address.town">{{ address.town }}</p>
        <p v-if="address.state">{{ address.state }}</p>
        <p v-if="address.postcode">{{ address.postcode }}</p>
        <p v-if="address.country">{{ address.country.name }}</p>
      </div>

      <div class="row mt-2">
        <div class="col-auto">
          <p class="mb-1 text-dark small font-italic">
            <span class="text-primary">Visibility: </span
            >{{ address.visibility }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: { type: Object },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/base/_variables.scss';
.card {
  &.address {
    border: none;
    background-color: $color-grey-lightest;

    .card-body {
      padding: 20px;
    }

    .address-entry {
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
