<template>
  <b-overlay>
    <div class="card">
      <div class="card-body general-details">
        <h4 class="font-weight-bold d-block mb-4">
          Roles in IFAC
        </h4>
        <!--  -->
        <div class="row my-4">
          <div class="col">
            <p>
              The data relative to roles in IFAC is filled by the Secretariat.
              If some data is missing, please contact the Secretariat.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col mt-3">
            <p>
              <a :href="mx_certificateLink(user, 'ifac')" v-b-tooltip.hover
                 title="Download certificate">
                <font-awesome-icon class="mr-1"
                                   role="button"
                                   :icon="['fal', 'download']"/>
              </a>
              IFAC Affiliate
            </p>
            <p v-for="(formattedPosition, key) in mx_sortedAffiliatePositions" :key="key">
              <a :href="mx_certificateLink(user, 'role', formattedPosition.position)"
                 v-b-tooltip.hover title="Download certificate">
                <font-awesome-icon class="mr-1"
                                   role="button"
                                   :icon="['fal', 'download']"/>
              </a>
              {{ mx_getPositionName(formattedPosition) }}
              <span class="d-block alert alert-light p-2 border" v-if="formattedPosition.notes">
                <IfacRoleNotes :text="formattedPosition.notes"/>
              </span>
            </p>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex';
import { ifacPositionsMixin, IfacRoleNotes } from '@ifac/ui';

export default {
  components: { IfacRoleNotes },
  mixins: [ifacPositionsMixin],
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    currentPositions() {
      return this.user?.positions;
    },
  },
};
</script>
