<template>
  <b-container class="profile-edit data-entry">
    <div class="header-notice">
      <div class="required-text">
        All fields are required unless otherwise indicated.
      </div>
      <div class="public-data-notice">
        We care about privacy of your data. It is your choice to select among <span class="options"
        >Web public, IFAC public</span> and <span class="options">Private</span> options.
      </div>
    </div>
    <tabs
      :tabItems="TAB_ITEMS"
      defaultTab="general-details">
      <template slot="tab-content">
        <component
          :is="activeTabComponent"/>
        <router-view />
      </template>
    </tabs>
  </b-container>
</template>

<script>
import { IfacLoader } from '@ifac/ui';
import { AffiliateProfileTabs } from '@/services/tabs';
import Tabs from '@/views/components/Tabs.vue';
import Roles from '@/views/areas/account/edit-profile-partials/Roles.vue';
import Addresses from '@/views/areas/account/edit-profile-partials/Addresses.vue';
import ContactDetails from '@/views/areas/account/edit-profile-partials/ContactDetails.vue';
import GeneralDetails from '@/views/areas/account/edit-profile-partials/GeneralDetails.vue';

export default {
  components: {
    IfacLoader,
    ContactDetails,
    GeneralDetails,
    Addresses,
    Roles,
    Tabs,
  },
  data() {
    return {
      busy: false,
    };
  },
  created() {
    this.TAB_ITEMS = AffiliateProfileTabs;
  },
  computed: {
    activeTabComponent() {
      return this.$route.query.section ?? this.TAB_ITEMS[0]?.name;
    },
  },
};
</script>
<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.profile-edit {
  padding-bottom: 2.5rem;
  .header-notice {
    padding: 0 0 1rem 0;
    .required-text {
      font-size: 16px;
    }

    .public-data-notice {
      font-size: 14px;
      color: $color-grey-dark;
      .options {
        color: $brand-dark-blue;
      }
    }
  }

  .card {
    padding: 0;
    .submit-buttons {
      padding-bottom: 1.25rem;
    }
  }
}
</style>
