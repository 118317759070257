export const AffiliateProfileTabs = [
  {
    label: 'General Details',
    name: 'general-details',
  },
  {
    label: 'Contact Details',
    name: 'contact-details',
  },
  {
    label: 'Addresses',
    name: 'addresses',
  },
  {
    label: 'Roles in IFAC',
    name: 'roles',
  },
];

export const ConferenceSectionTabs = [
  {
    label: 'Active',
    name: 'active',
  },
  {
    label: 'Historic',
    name: 'historic',
  },
];

export const otherTabs = [];
