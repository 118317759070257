<template>
  <div>
    <div class="row input-row">
      <div class="col-12">
        <div class="row align-items-end no-gutters">
          <div
            @click="openInputDialog"
            class="
              profile-avatar
              uploader
              rounded-lg
              d-flex
              float-left
              align-items-center
              justify-content-start
              cursor-pointer"
            :style="currentAvatar ? `
              background-image: url(${currentAvatar});
            ` : ''"
          >
            <div class="row" v-if="!currentAvatar">
              <div class="col-12">
                <i class="far fa-camera"></i>
              </div>
              <div class="col-12">
                <span class="font-weight-lighter">Upload</span>
              </div>
            </div>
          </div>
          <a
            href="#"
            v-if="currentAvatar"
            class="d-inline-block ml-2"
            @click.prevent="onRemoveClicked"
          >
            <i class="far fa-trash"></i>
          </a>
        </div>
      </div>
      <div class="col-12">
        <div v-if="!isImageChosen">
          <b-form-file
            class="d-none"
            ref="avatarFileInput"
            v-model="localFile"
            :state="chooseButtonState"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :accept="this.type"
            size="sm"
          ></b-form-file>
          <b-form-invalid-feedback v-if="this.error">
            {{ this.error }}
          </b-form-invalid-feedback>
          <b-form-valid-feedback v-if="this.success">
            {{ this.success }}
          </b-form-valid-feedback>
        </div>
      </div>
      <div class="col-10" v-if="formPrivacy">
        <ifac-privacy-selector
          v-model="formPrivacy"
        ></ifac-privacy-selector>
      </div>
    </div>
  </div>
</template>
<script>
import Affiliates from '@/services/Api/Affiliates';
import S3Uploader from '@/services/S3Uploader';
import {
  IfacPrivacySelector,
} from '@ifac/ui';

export default {
  components: {
    IfacPrivacySelector,
  },
  props: {
    affiliateId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'image/*',
    },
    minKb: {
      type: Number,
      default: 20,
    },
    maxKb: {
      type: Number,
      default: 3000,
    },
    avatar: {
      type: String,
    },
    privacy: {
      type: String,
    },
  },
  methods: {
    openInputDialog() {
      this.resetState();
      this.$refs.avatarFileInput.$refs.input.click();
    },
    onCancelClicked() {
      this.resetLocalFile();
    },
    onSetAsProfileClicked() {
      this.setAvatar();
    },
    onRemoveClicked() {
      this.removeAvatar();
    },
    onError(error) {
      this.error = error;
      this.$emit('failed');
    },
    onSuccess(msg) {
      this.success = msg;
      this.$emit('succeeded');
    },
    validateLocalFile() {
      if (this.localFile?.size < this.minKb * 1024) {
        this.onError(`The chosen file is smaller than ${this.minKb}kB`);
        this.resetLocalFile();
      }
      if (this.localFile?.size > this.maxKb * 1024) {
        this.onError(`The chosen file is bigger than ${this.maxKb}kB`);
        this.resetLocalFile();
      }
    },
    resetLocalFile() {
      this.localFile = null;
    },
    resetState() {
      this.error = null;
      this.success = null;
    },
    removeAvatar() {
      this.resetState();
      this.currentAvatar = null;
      this.$emit('update-avatar', null);
    },
    setAvatar() {
      this.busy = true;
      this.currentAvatar = this.localFileUrl;

      // 1, request upload url
      Affiliates.avatarUpload(
        this.affiliateId,
        {
          fileName: this.localFile.name,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      ).then((response) => {
        // 2. upload image to S3
        const { id: uploadId, url } = response.data.data;
        S3Uploader.put(url, this.localFile)
          .then(() => {
            // 3. send affiliate avatar to parent component to be managed by it.
            this.$emit('update-avatar', uploadId);
          }).catch(() => {
            this.onError('Something went wrong while uploading the photo, please try again.');
          });
      }).catch(() => {
        this.onError('Something went wrong while uploading the photo, please try again.');
      }).finally(() => {
        this.resetLocalFile();
        this.busy = false;
      });
    },
  },
  computed: {
    chooseButtonState() {
      /* eslint-disable no-else-return */
      if (this.error !== null) {
        return false;
      } else if (this.success !== null) {
        return true;
      } else {
        return null;
      }
    },
    isBusy() {
      return this.busy;
    },
    isImageChosen() {
      return this.localFile !== null;
    },
    localFileUrl() {
      return URL.createObjectURL(this.localFile);
    },
  },
  watch: {
    localFile: {
      handler(localFile) {
        this.validateLocalFile();
        if (localFile && !this.error) {
          this.setAvatar();
        } else {
          this.resetLocalFile();
        }
      },
    },
    formPrivacy: {
      handler(val) {
        this.$emit('privacy', val);
      },
    },
  },
  data() {
    return {
      currentAvatar: null,
      busy: false, // true when uploading photo
      success: null, // message to display when everything went ok
      error: null, // message to display when there's been an error
      localFile: null, // local file chosen by user
      formPrivacy: this.privacy, // variable to change the avatar privacy
    };
  },
  created() {
    this.currentAvatar = this.avatar;
  },
};
</script>

<style scoped lang='scss'>
@import '@/assets/styles/base/_variables.scss';
.profile-avatar {
  text-align: center;
  justify-content: center;
  color: $brand-dark-blue;
  font-weight: 700;
  .fa-camera {
    font-size: 2rem;
  }

  .fa-trash {
    margin-left: 6.875rem;
    margin-top: 5.313rem;
  }
}
</style>
