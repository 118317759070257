<template>
  <b-overlay :show="busy">
    <div class="card">
      <div class="card-body">
        <h3 class="mb-2">Contact Details</h3>
        <ifac-base-label
          :text-styles="['extra-small']"
          :class="['font-weight-light', 'font-italic', 'mb-4']"
          text="
            The IFAC secretariat would appreciate having at least one email
            and one phone number to contact you. Does not need to be public.
          "
        />
        <div
          :key="format.id"
          class="contact-details"
          v-for="(format, i) in contactFormats"
        >

          <div class="d-flex align-items-end" v-if="format.id === 'email'">
            <h4 class="w-75">{{ format.name }}</h4>
            <div class="ml-3 mr-3"></div>
            <div class="w-50 small text-center" v-if="format.id === 'email'">
              <div class="mb-2">
                I approve to receive email notifications at this(these) address(es)
              </div>
              <div class="d-flex align-items-center">
                <div class="w-50">IFAC Newsletter</div>
                <div class="w-50">IFAC Activities (Conferences etc.)</div>
              </div>
            </div>
          </div>

          <h4 v-else>{{ format.name }}</h4>

          <div
            class="row align-items-center"
            :key="`${format.id}-${item.id}`"
            v-for="(item, index) in $data[`${format.id}List`]"
          >

            <contact-details-updater
              :format="format"
              :countries-list="countries"
              :contact-types="contactTypes"
              @has-errors="invalid = $event"
              v-model="$data[`${format.id}List`][index]"
              @delete-contact-value="deleteContact(`${format.id}List`, index)"
            />
          </div>
          <div class="row">
            <div class="col">
              <button
                class="btn"
                type="button"
                @click="createContact(format.id)"
              >Add Entry
              </button>
            </div>
          </div>
          <hr
            style="border-style: dashed"
            v-if="i !== (contactFormats.length - 1)"
          />
        </div>
      </div>
      <b-row class="submit-buttons">
        <b-col cols="2">
          <button type="reset" class="btn btn-light" @click="cancel">
            Cancel
          </button>
        </b-col>
        <b-col cols="10" class="text-right right-col">
          <button class="btn btn-secondary previous"
            @click.prevent="pushToPreviousSection"
          >
            Previous
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="invalid"
            @click.prevent="saveContactDetails"
          >
            Save and continue
          </button>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { IfacBaseLabel, ifacContactsMixin } from '@ifac/ui';
import Affiliates from '@/services/Api/Affiliates';
import ContactDetailsUpdater from '@/views/areas/account/edit-profile-partials/contact-partials/ContactDetailsUpdater.vue';

export default {
  components: {
    IfacBaseLabel,
    ContactDetailsUpdater,
  },
  created() {
    if (!this.contactTypes.length) this.fetchContactTypes();
    if (!this.contactFormats.length) this.fetchContactFormats();
    if (!this.countries.length) this.fetchCountries();
  },
  mounted() {
    this.prepareLists();
  },
  mixins: [ifacContactsMixin],
  data() {
    return {
      busy: true,
      invalid: false,
      emailList: [],
      phoneList: [],
      linkList: [],
      faxList: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    ...mapState({
      contactTypes: (state) => state.contactTypes.data,
      contactFormats: (state) => state.contactFormats.data,
    }),
    countriesSorted() {
      return [...this.$store.state.countries.data].sort((a, b) => {
        if (a.code3 < b.code3) {
          return -1;
        }
        if (a.code3 > b.code3) {
          return 1;
        }
        return 0;
      });
    },
    countries() {
      return this.countriesSorted.map((c) => {
        const prefix = `${c.code3} (+${c.e164})`;
        return {
          ...c,
          prefix,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchCountries: 'countries/fetchCountries',
      fetchContactTypes: 'contactTypes/fetchContactTypes',
      fetchContactFormats: 'contactFormats/fetchContactFormats',
      setUser: 'auth/setUser',
    }),
    prepareLists() {
      this.emailList = this.user?.contactInfo?.filter((contact) => contact.format === 'email');
      this.phoneList = this.user?.contactInfo?.filter((contact) => contact.format === 'phone');
      this.faxList = this.user?.contactInfo?.filter((contact) => contact.format === 'fax');
      this.linkList = this.user?.contactInfo?.filter((contact) => contact.format === 'link');

      this.busy = false;
    },
    preparePayload() {
      const contacts = [
        ...this.mx_sanitizeList(this.emailList),
        ...this.mx_sanitizePhoneList(this.phoneList),
        ...this.mx_sanitizePhoneList(this.faxList),
        ...this.mx_sanitizeList(this.linkList),
      ];

      return {
        ...{ id: this.user?.id },
        ...{ contactInfo: contacts },
      };
    },
    contactObject(key) {
      return {
        id: null,
        affiliateId: this.user?.id,
        format: key,
        type: 'work',
        info: '',
        visibility: 'private',
        notifications: {
          ifac_activities: false,
          ifac_newsletter: false,
        },
      };
    },
    createContact(key) {
      this.$data[`${key}List`].push(this.contactObject(key));
    },
    deleteContact(field, i) {
      this.$data[field].splice(i, 1);
    },
    cancel() {
      this.$router.push({
        name: 'Home',
      });
    },
    pushToSection(section) {
      this.$router.push({
        name: 'ProfileEdit',
        query: {
          section,
        },
        params: { id: this.user?.id },
      });
    },
    pushToPreviousSection() {
      this.pushToSection('general-details');
    },
    pushToNextSection() {
      this.pushToSection('addresses');
    },
    async saveContactDetails() {
      this.busy = true;
      try {
        const response = await Affiliates.update(this.preparePayload());
        this.setUser(response);
        this.$snack.success({
          text: 'Your contact details were updated successfully!',
        });
        this.pushToNextSection();
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors = error.response.data.error?.details;
          this.$snack.success({
            text: 'You have some validation errors, try again please.',
          });
        }
      } finally {
        this.busy = false;
      }
    },
  },
};

</script>

<style scoped lang='scss'>
@import '@/assets/styles/base/_mixins.scss';

.btn {
  @include media(mobile-small) {
    &.previous {
      display: none;
    }
  }
}
</style>

<style lang='scss'>
@import '@/assets/styles/base/_mixins.scss';
@import '@/assets/styles/base/_variables.scss';

.contact-details {
  .ifac-privacy-selector {
    margin-bottom: 0.625rem;
  }

  .contact-type {
    .btn {
      background-color: $color-grey-lightest;
      color: $color-black-lighter;

      &:hover {
        color: $color-black-lighter;
      }
    }
  }

  .ifac-search-dropdown {
    max-width: 150px;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
