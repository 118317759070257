<template>
  <b-modal
    id="address-form"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    no-stacking
    hide-header
    hide-footer
    modal-class="data-entry"
  >
    <h3>{{ title }}</h3>
    <div class="row input-row">
      <div class="col-md-6">
        <ifac-dropdown
          title="Address Type *"
          v-model="$v.editedAddress.type.$model"
          :list="addressTypes"
        />
        <p
          class="mb-0 mt-1 text-danger small"
          v-if="$v.editedAddress.type.$error"
        >This input is required</p>
      </div>
    </div>
    <div class="row input-row">
      <div class="col-md-6">
        <ifac-validator-input
          name="line1"
          required
          title="Address Line 1"
          v-model="$v.editedAddress.line1.$model"
          :has-error="$v.editedAddress.line1.$error"
          error-message="This input is required"
        />
      </div>
      <div class="col-md-6">
        <ifac-validator-input
          v-model="editedAddress.line2"
          name="line2"
          title="Address Line 2"
        />
      </div>
    </div>
    <!--  -->
    <div class="row input-row">
      <div class="col-md-6">
        <ifac-validator-input
          v-model="editedAddress.line3"
          name="line3"
          title="Address Line 3"
        />
      </div>
      <div class="col-md-6">
        <ifac-validator-input
          required
          name="town"
          title="Town"
          v-model="$v.editedAddress.town.$model"
          :has-error="$v.editedAddress.town.$error"
          error-message="This input is required"
        />
      </div>
    </div>
    <!--  -->
    <div class="row input-row">
      <div class="col-md-6">
        <ifac-validator-input
          v-model="editedAddress.state"
          name="state"
          title="State"
        />
      </div>
      <div class="col-md-6">
        <ifac-validator-input
          required
          name="postcode"
          title="Post Code"
          v-model="$v.editedAddress.postcode.$model"
          :has-error="$v.editedAddress.postcode.$error"
          error-message="This input is required"
        />
      </div>
    </div>
    <!--  -->

    <div class="row input-row">
      <div class="col-md-6">
        <label for="">Country / Region <span>*</span></label>
        <ifac-search-dropdown
          :options="countries"
          :current="editedAddress.country"
          currentType="object"
          currentValue="name"
          optionValue="code"
          optionTitle="name"
          searchAgainst="name"
          emitType="object"
          emitValue="name"
          @selected="editedAddress.country = $event"
          placeholder="Select a country"
        />
        <p
          class="mb-0 mt-1 text-danger small"
          v-if="$v.country.$error"
        >This input is required</p>
        <ifac-privacy-selector
          v-model="editedAddress.visibility"
        />
      </div>
    </div>

    <b-row class="submit-buttons">
      <b-col cols="2">
        <button type="reset" class="btn btn-light" @click="hideModal">
          Cancel
        </button>
      </b-col>
      <b-col cols="10" class="text-right right-col">
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="!formIsValid"
          @click.prevent="save"
        >
          Save
        </button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {
  IfacPrivacySelector,
  IfacSearchDropdown,
  IfacValidatorInput,
  IfacDropdown,
} from '@ifac/ui';

export default {
  components: {
    IfacPrivacySelector,
    IfacSearchDropdown,
    IfacDropdown,
    IfacValidatorInput,
  },
  props: {
    affiliate: {
      type: Object,
    },
    address: {
      type: Object,
      default() {
        return {
          id: '',
          line1: '',
          line2: '',
          line3: '',
          town: '',
          state: '',
          postcode: '',
          country: { code: '', id: '', name: '' },
          type: '',
          visibility: 'private',
        };
      },
    },
  },
  data() {
    return {
      editedAddress: { ...this.address },
    };
  },
  validations: {
    country: { required },
    editedAddress: {
      line1: { required },
      town: { required },
      postcode: { required },
      type: { required },
    },
  },
  watch: {
    address(newAddress) {
      this.editedAddress = { ...newAddress };
    },
    country(country) {
      if (country && country !== '') {
        this.$v.country.$touch();
      }
    },
  },
  computed: {
    ...mapGetters({
      addressTypes: 'addressTypes/data',
      countries: 'countries/data',
    }),
    country() {
      return this.editedAddress?.country?.code;
    },
    formIsValid() {
      return !this.$v.$invalid;
    },
    title() {
      return this.editedAddress.id === '' ? 'Add new address' : 'Edit address';
    },
  },
  methods: {
    hideModal() {
      this.$v.$reset();
      this.$bvModal.hide('address-form');
    },
    save() {
      const updatedAddress = {
        ...this.editedAddress,
        countryCode: this.editedAddress.country.code,
      };
      this.$emit('saved', updatedAddress);
      this.$v.$reset();
      this.$bvModal.hide('address-form');
    },
  },
};
</script>

<style scoped lang="scss">
.submit-buttons {
  margin: 0 -15px;
}
</style>

<style lang="scss">
.modal-dialog {
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    margin: 0 0 2.75rem 0;
    border: none;
    @media (min-width: 768px) {
      height: calc(100vh - 8.125rem);
      max-height: calc(100vh - 8.125rem);
      width: 80%;
      max-width: 800px;
      margin: 1.75rem auto;
    }
    .modal-content {
      border-radius: 0;
      border: none;
      @media (min-width: 768px) {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
      }
    }
}
</style>
