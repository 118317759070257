import axios from 'axios';

class S3Uploader {
  /**
   * PUTs a file in S3
   * @returns {AxiosPromise<any>}
   */
  static put(url, file) {
    return axios.create().put(
      url,
      file,
      {
        headers: {
          'Content-Type': file.type,
        },
      },
    );
  }
}

export default S3Uploader;
