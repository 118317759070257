<template>
  <b-overlay :show="busy">
    <div class="card">
      <validation-observer v-slot="{ invalid }">
        <div class="card-body">
          <h3>Profile</h3>

          <!-- Avatar Section -->

          <div v-if="ready">
            <avatar-uploader
              :avatar="form.avatar"
              :affiliate-id="form.id"
              @update-avatar="updateAvatarId"
              :privacy="form.avatarVisibility"
              @privacy="form.avatarVisibility = $event"
            />

            <!-- Inputs Section -->

            <general-details-inputs
              :value="form"
              @valid="countryIsValid = $event"
              @form-changed="form = $event"
            />

            <!-- FOI Section -->

            <div class="row">
              <div class="col-12">
                <h4>Fields of interest</h4>
                <ifac-currently-selected-fields
                  v-show="selectedKws.length > 0"
                  :selected-kws="selectedKws"
                />
              </div>
              <div class="col-12">
                <button class="btn" v-b-modal.ifac-fields-of-interest>
                  {{ fieldsOfInterestLabel() }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ifac-privacy-selector
                  v-model="form.fieldsOfInterestVisibility"
                />
              </div>
            </div>

            <!-- FOI Selector Section -->
            <ifac-interest-selector
              :selected-keywords="selectedKws"
              :selected-technical-committees="selectedTcs"
              @updateTcs="updateTcs"
              @updateKws="updateKeywords"
            />
          </div>
        </div>
        <b-row class="submit-buttons">
          <b-col cols="2">
            <button type="reset" class="btn btn-light" @click="cancel">
              Cancel
            </button>
          </b-col>
          <b-col cols="10" class="text-right right-col">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="invalid || !countryIsValid"
              @click.prevent="updateAffiliate"
            >
              Save and continue
            </button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Affiliates from '@/services/Api/Affiliates';
import {
  IfacInterestSelector,
  IfacCurrentlySelectedFields,
  IfacPrivacySelector,
  IfacAffiliatesHelpers,
} from '@ifac/ui';
import AvatarUploader from './general-partials/AvatarUploader.vue';
import GeneralDetailsInputs from './general-partials/GeneralDetailsInputs.vue';

export default {
  components: {
    AvatarUploader,
    IfacPrivacySelector,
    IfacInterestSelector,
    IfacCurrentlySelectedFields,
    GeneralDetailsInputs,
  },
  data() {
    return {
      busy: true,
      ready: false,
      countryIsValid: false,
      step: 1,
      selectedTcs: [],
      selectedKws: [],
      form: {
        id: '',
        avatar: null,
        avatarVisibility: '',
        title: '',
        titleVisibility: '',
        name: '',
        country: {},
        notesForSecretariat: '',
        surname: '',
        fullNameVisibility: '',
        gender: '',
        genderVisibility: '',
        disability: '',
        disabilityVisibility: '',
        affiliation: '',
        affiliationVisibility: '',
        industryAcademiaGovernment: '',
        industryAcademiaGovernmentVisibility: '',
        jobPosition: '',
        jobPositionVisibility: '',
        careerStage: '',
        careerStageVisibility: '',
        jobDescription: '',
        jobDescriptionVisibility: '',
        countryCode: '',
        countryVisibility: '',
        fieldsOfInterest: [],
        fieldsOfInterestVisibility: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      ccs: 'coordinatingCommittees/data',
      user: 'auth/user',
    }),
    avatarIsUpdated() {
      return this.user.avatar !== this.form.avatar;
    },
  },
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
    }),
    fieldsOfInterestLabel() {
      return (this.selectedKws.length > 0) ? 'Update fields of interest' : 'Select fields of interest';
    },
    cancel() {
      this.$router.push({
        name: 'Home',
      });
    },
    preparePayload() {
      const data = { ...this.form };
      data.fieldsOfInterest = data.fieldsOfInterest.map((fOI) => {
        const fOIisObject = typeof fOI === 'object';
        return fOIisObject ? fOI.id : fOI;
      });
      return this.cleanEditedAffiliateObject(data);
    },
    cleanEditedAffiliateObject(data) {
      return Object.fromEntries(
        Object.entries(data).filter(([i, v]) => {
          const isAvatar = i === 'avatar';
          const hasValue = v != null;

          return (isAvatar && this.avatarIsUpdated) || (!isAvatar && hasValue);
        }),
      );
    },
    pushToNextSection() {
      this.$router.push({
        name: 'ProfileEdit',
        query: { section: 'contact-details' },
        params: { id: this.user.id },
      });
    },
    async updateAffiliate() {
      this.busy = true;
      try {
        const response = await Affiliates.update(this.preparePayload());

        // Set current affiliate
        this.setUser(response);

        // Show success message
        this.$snack.success({
          text: 'Your profile was updated successfully!',
        });

        // Show modal for private name
        if (this.user.fullNameVisibility === 'private') {
          setTimeout(() => this.$bvModal.msgBoxOk(
            'Your Name is set to be “Private”. By this setting no other affiliate can see your name in the list of IFAC Affiliates.',
            {
              title: '',
              size: 'sm',
              headerClass: 'border-bottom-0',
              footerClass: 'border-top-0',
              centered: true,
            },
          ), 2000);
        }

        // Go to next section
        this.pushToNextSection();
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors = error.response.data.error?.details;
          this.$snack.success({
            text: error.response.data.error.message,
          });
        } else {
          this.$snack.success({
            text: 'You have some validation errors, try again please.',
          });
        }
      } finally {
        this.busy = false;
      }
    },
    updateAvatarId(id) {
      this.form.avatar = id;
    },
    updateTcs(tcs) {
      this.selectedTcs = tcs;
    },
    updateKeywords(keywords) {
      this.selectedKws = IfacAffiliatesHelpers.sortFoiKeywords(keywords);
      this.form.fieldsOfInterest = keywords.map((a) => a.id);
    },
    initializeFieldsOfInterest() {
      const tcs = [];
      const kws = [];

      this.user.fieldsOfInterest.forEach((kw) => {
        const tc = kw.technicalCommittee;
        tc.cc = this.ccs.find((i) => i.number === tc.ccNumber);
        delete tc.technicalCommittee;
        kw.number = tc.number;

        tc.keywords = tc.cc.technicalCommittees.find((i) => i.number === tc.number).keywords;

        const exists = tcs.findIndex((t) => t.name === tc.name);

        if (exists === -1) {
          tcs.push(tc);
        }

        kws.push(kw);
      });

      this.selectedTcs = tcs;
      this.selectedKws = IfacAffiliatesHelpers.sortFoiKeywords(kws);
    },
    initializeForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.user[key];
      });

      this.initializeFieldsOfInterest();

      this.ready = true;
      this.busy = false;
    },
  },
  mounted() {
    this.initializeForm();
  },
};
</script>

<style lang='scss'>
.selected-keywords {
  padding-bottom: 0.625rem;
}

</style>
